<template>
  <div class="report-index">
    <app-title-content
      v-if="!isPublic"
      class="mb-4"
      :items="breadcrumbs"
      name="ReportIndex"
    ></app-title-content>
    <!-- <div class="d-flex justify-content-between mb-4">
      <button
        type="submit"
        class="btn btn-transparent px-0 text-left"
        @click="gotoMenuPage"
      >
        <iconify icon="akar-icons:arrow-left" class="mb-1 mx-2" /> ย้อนกลับ
      </button>
      <button type="submit" class="btn btn-outline-submit">
        <iconify icon="fa-solid:home" class="fontsize-h4 mr-2" />
        กลับไปยังหน้ารายการ
      </button>
    </div> -->
    <div class="font-headblue fontsize-h4 d-flex align-items-center mb-4">
      <iconify icon="ant-design:file-filled"></iconify>
      <span class="weight-700 ml-1">สรุปผลแบบสำรวจระบบประปาหมู่บ้าน</span>
    </div>
    <b-overlay :show="isLoadReport">
      <div class="row">
        <div class="col">
          <div class="d-flex">
            <b-card class="card-custom col">
              <div class="d-flex justify-content-between align-items-center">
                <span>จังหวัด</span
                ><span class="font-headblue fontsize-h3 weight-700">{{
                  provinceCount | toCommas
                }}</span>
              </div>
            </b-card>
            <b-card class="card-custom col ml-2">
              <div class="d-flex justify-content-between align-items-center">
                <span>องค์การบริหารส่วนท้องถิ่น</span
                ><span class="font-headblue fontsize-h3 weight-700">{{
                  optCount | toCommas
                }}</span>
              </div>
            </b-card>
            <b-card class="card-custom col ml-2">
              <div class="d-flex justify-content-between align-items-center">
                <span>หมู่บ้าน</span
                ><span class="font-headblue fontsize-h3 weight-700">{{
                  villageCount | toCommas
                }}</span>
              </div>
            </b-card>
            <b-card class="card-custom col ml-2">
              <div class="d-flex justify-content-between align-items-center">
                <span>ระบบประปา</span
                ><span class="font-headblue fontsize-h3 weight-700">{{
                  waterSupplySystemCount | toCommas
                }}</span>
              </div>
            </b-card>
          </div>
          <div class="box-shadow my-3">
            <div class="d-flex justify-content-between">
              <span class="weight-700"
                >แหล่งน้ำดิบที่ใช้ในระบบประปาหมู่บ้าน</span
              >
            </div>
            <div class="row pt-5 pb-4 pl-5 pr-5">
              <!-- graph -->
              <div class="col-4">
                <apexchart
                  width="300"
                  type="pie"
                  :options="chartOptionsPie"
                  :series="seriesPie"
                ></apexchart>
              </div>
              <div class="col-8">
                <b-table
                  id="my-table"
                  hover
                  borderless
                  :no-border-collapse="true"
                  :items="itemsSummary"
                  :fields="fieldsSummary"
                  class="table-custom"
                >
                  <template v-slot:empty="">
                    <div class="text-center">ไม่พบข้อมูล</div>
                  </template>
                  <template v-slot:head()="data">
                    <div
                      :class="
                        data.field.align === 'center'
                          ? 'text-center'
                          : 'text-left'
                      "
                    >
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div
                      :class="
                        data.field.align === 'center'
                          ? 'text-center'
                          : 'text-left'
                      "
                    >
                      {{ data.value }}
                    </div>
                  </template>
                  <template v-slot:cell(type)="data">
                    <div class="d-flex align-items-center">
                      <iconify
                        icon="ic:baseline-water-drop"
                        class="rounded-circle p-1 fontsize-h4 mr-2"
                        :class="
                          data.item.pwaName === 'แหล่งน้ำบาดาล'
                            ? 'icon-green'
                            : 'icon-yellow'
                        "
                      ></iconify>
                      {{ data.item.pwaName }}
                    </div>
                  </template>
                  <!-- <template v-slot:cell(change)="data">
                  <div class="">
                    {{ data.item.change }}
                    {{ data.item.percentChange }}%
                    <iconify
                      :icon="
                        data.item.change === 'เพิ่มขึ้น'
                          ? 'ant-design:caret-up-filled'
                          : 'ant-design:caret-down-filled'
                      "
                      class="fontsize-h4 ml-2"
                      :class="
                        data.item.typeId === 1
                          ? 'font-strongcyan'
                          : 'font-softorange'
                      "
                    ></iconify>
                  </div>
                </template> -->
                </b-table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="header d-flex align-items-center">
            <iconify
              icon="ic:baseline-water-drop"
              class="mr-2 fontsize-h4"
            ></iconify
            >การบริหารระบบประปา
          </div>
          <div class="box mt-3 p-2">
            <div class="d-flex justify-content-between">
              <div class="align-items-center d-flex">
                <iconify
                  icon="gridicons:user"
                  class="fontsize-icon icon-layer"
                ></iconify>
                <div class="ml-4">
                  <div><span>องค์การปกครองส่วนท้องถิ่น</span></div>
                  <div>
                    <span class="font-headblue fontsize-h3 weight-700">{{
                      managementBy_OPCCount | toCommas
                    }}</span>
                  </div>
                </div>
              </div>
              <span class="d-flex align-items-end font-disable">แห่ง</span>
            </div>
          </div>
          <div class="box mt-3 p-2">
            <div class="d-flex justify-content-between">
              <div class="align-items-center d-flex">
                <iconify
                  icon="gridicons:user"
                  class="fontsize-icon icon-user"
                ></iconify>
                <div class="ml-4">
                  <div><span>คณะกรรมการบริหารกิจการประปาหมู่บ้าน</span></div>
                  <div>
                    <span class="font-headblue fontsize-h3 weight-700">{{
                      managementBy_BoardCount | toCommas
                    }}</span>
                  </div>
                </div>
              </div>
              <span class="d-flex align-items-end font-disable">แห่ง</span>
            </div>
          </div>
          <div class="box mt-3 p-2">
            <div class="d-flex justify-content-between">
              <div class="align-items-center d-flex">
                <iconify
                  icon="gridicons:user"
                  class="fontsize-icon icon-widget"
                ></iconify>
                <div class="ml-4">
                  <div><span>คณะกรรมการหมู่บ้าน</span></div>
                  <div>
                    <span class="font-headblue fontsize-h3 weight-700">{{
                      managementBy_VillageCount | toCommas
                    }}</span>
                  </div>
                </div>
              </div>
              <span class="d-flex align-items-end font-disable">แห่ง</span>
            </div>
          </div>
          <div class="box mt-3 p-2">
            <div class="d-flex justify-content-between">
              <div class="align-items-center d-flex">
                <iconify
                  icon="gridicons:user"
                  class="fontsize-icon icon-other"
                ></iconify>
                <div class="ml-4">
                  <div><span>อื่นๆ</span></div>
                  <div>
                    <span class="font-headblue fontsize-h3 weight-700">{{
                      managementBy_Another | toCommas
                    }}</span>
                  </div>
                </div>
              </div>
              <span class="d-flex align-items-end font-disable">แห่ง</span>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <div class="font-headblue fontsize-h4 d-flex align-items-center my-4">
      <iconify icon="ant-design:pie-chart-filled"></iconify>
      <span class="weight-700 ml-1"
        >มาตรฐานคุณภาพระบบประปาหมู่บ้าน 5 เกณฑ์ ในภาพรวมทั้งประเทศ
      </span>
    </div>
    <div class="box-shadow p-4">
      <b-overlay :show="isLoadSummary">
        <div class="d-flex justify-content-between">
          <div>
            <span class="weight-700"
              >จากการประเมินคุณภาพระบบประปา จำนวน
              {{ total_QualitySurvey | toCommas }} แห่ง</span
            >
            <!-- <span class="font-disgray ml-2"></span> -->
          </div>
          <!-- <button class="btn btn-gray text-left" @click="isShowModal = true">
            <iconify icon="ant-design:info-circle-outlined" class="mb-1 mx-2" />
            หลักเกณฑ์การประเมิน
          </button> -->
          <button class="btn btn-submit" @click="viewSuggestion">
            <iconify icon="ant-design:info-circle-outlined" class="mb-1 mr-2" />
            ข้อแนะนำหลังการประเมิน
          </button>
        </div>
        <div class="row mt-4">
          <div class="col-3 box mx-4 p-4">
            <span class="weight-700 ml-3">การประเมินคุณภาพระบบประปา</span>
            <bar-chart-1 :series="series" :result="chartResult"></bar-chart-1>
          </div>
          <div class="col">
            <!-- {{ itemsAssessmentSummary }} -->
            <b-table
              id="my-table2"
              class="table-custom box-shadow mb-4"
              hover
              borderless
              show-empty
              :items="itemsAssessmentSummary"
              :fields="fieldsAssessmentSummary"
            >
              <template v-slot:empty="">
                <div class="text-center">ไม่พบข้อมูล</div>
              </template>
              <template v-slot:head()="data">
                <div
                  :class="
                    data.field.align === 'left'
                      ? 'text-left'
                      : data.field.align === 'right'
                      ? 'text-right'
                      : 'text-center'
                  "
                >
                  {{ data.label }}
                </div>
              </template>

              <template v-slot:head(index)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div
                  v-if="data.item.alignRow !== undefined"
                  :class="
                    data.item.alignRow === 'left'
                      ? 'text-left'
                      : data.item.alignRow === 'right'
                      ? 'text-right'
                      : 'text-center'
                  "
                >
                  {{ data.value }}
                </div>
                <div
                  v-else
                  :class="
                    data.field.align === 'left'
                      ? 'text-left'
                      : data.field.align === 'right'
                      ? 'text-right'
                      : 'text-center'
                  "
                >
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(statusText)="data">
                <div>
                  <iconify
                    class="fontsize-h5"
                    :icon="getIcon(data.item.statusTextSymbol)"
                    :class="getColorIcon(data.item.statusTextSymbol)"
                  />
                  {{ data.item.statusText }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div class="text-center" v-if="data.index < 5">
                  {{ data.index + 1 }}
                </div>
              </template>

              <template #custom-foot="">
                <b-tr class="footer-color">
                  <b-th></b-th>
                  <b-th>รวมทั้งหมด 5 ด้าน</b-th>
                  <b-th>{{ summary.score }}</b-th>
                  <b-th class="text-center">{{ summary.percent }}</b-th>
                  <b-th
                    ><iconify
                      :icon="getIcon(summary.assessment)"
                      :class="getColorIcon(summary.assessment)"
                      class="mr-1 fontsize-h5"
                    /><span class="weight-500">{{
                      summary.assessmentText
                    }}</span></b-th
                  >
                </b-tr>
              </template>
            </b-table>
          </div>
        </div>
      </b-overlay>
    </div>

    <modal-assess :modalShow="isShowModal" @change="isShowModal = $event">
    </modal-assess>
    <div class="d-flex justify-content-center mt-4" v-if="!isPublic">
      <button
        type="submit"
        class="btn btn-outline-submit"
        @click="gotoMenuPage()"
      >
        <iconify icon="fa-solid:home" class="fontsize-h4 mr-2" />
        กลับไปยังหน้ารายการ
      </button>
    </div>
  </div>
</template>

<script>
import { ReportService, AssessmentService } from "@/services/main.service.js";
import ModalAssessment from "@/views/Assessment/ModalAssessment.vue";
export default {
  name: "report-index",
  components: {
    "modal-assess": ModalAssessment,
  },
  data() {
    const breadcrumbs = [
      {
        to: "ReportIndex",
        text: "รายงานผลการประเมิน",
      },
    ];
    return {
      isPublic: false,
      isLoadReport: false,
      isLoadSummary: false,
      isShowModal: false,
      breadcrumbs: breadcrumbs,
      fieldsSummary: [
        { key: "type", label: "ประเภท" },
        { key: "amount", label: "จำนวน (แห่ง)", align: "center" },
        { key: "percent", label: "เปอร์เซนต์", align: "center" },
      ],
      fieldsAssessmentSummary: [
        { key: "index", label: "ลำดับ", align: "center" },
        { key: "danNane", label: "ปัจจัย", align: "left" },
        { key: "score", label: "คะแนน", align: "left" },
        { key: "scorePercentage", label: "เปอร์เซนต์ (%)", align: "center" },
        { key: "statusText", label: "สถานะการประเมิน", align: "left" },
      ],
      itemsSummary: [],
      itemsAssessmentSummary: [],
      summary: {
        score: null,
        percent: null,
        assessment: null,
        assessmentText: null,
      },
      provinceCount: 0,
      optCount: 0,
      villageCount: 0,
      waterSupplySystemCount: 0,
      managementBy_OPCCount: 0,
      managementBy_BoardCount: 0,
      managementBy_VillageCount: 0,
      managementBy_Another: 0,

      total_QualitySurvey: 0,

      chartOptionsPie: {
        chart: {
          id: "vuechart-example-pie",
          background: "#fff",
          fontFamily: "Sarabun, sans-serif",
        },
        legend: { position: "bottom" },
        labels: [],
        colors: ["#2AD3CA", "#FDBD6E"],
        dataLabels: {
          formatter: function (val, opt) {
            return (
              opt.w.config.labelsInChart[opt.seriesIndex] +
              " " +
              val.toFixed(2) +
              " %"
            );
          },
          offsetX: 80,
          offsetY: 50,
        },
        labelsInChart: ["บาดาล", "ผิวดิน"],
      },
      seriesPie: [],
      series: [],
      chartResult: [],
    };
  },
  async created() {
    window._reportt = this;

    const _router = this.$route;
    this.isPublic = _router.matched.some((record) => record.meta.isPublic);
    this.getReport();
    await this.getSummary();
    let data = [];
    let chartResult = [];
    this.itemsAssessmentSummary.forEach((item) => {
      data.push(item.scorePercentage);
      chartResult.push({
        scorePercentage: item.scorePercentage,
        statusText: item.statusText,
        statusSymbol: item.statusTextSymbol,
      });
    });
    this.series = [
      {
        data: data,
      },
    ];
    this.chartResult = [...chartResult];
  },

  methods: {
    gotoMenuPage() {
      this.$router.push({ name: "MenuPage" });
    },
    getIcon(item) {
      if (item === "A") return "bi:emoji-heart-eyes-fill";
      else if (item === "B") return "bi:emoji-smile-fill";
      else if (item === "C") return "bi:emoji-wink-fill";
      else if (item === "D") return "bi:emoji-neutral-fill";
      else if (item === "F") return "bi:emoji-frown-fill";
    },
    getColorIcon(item) {
      if (item === "A") return "font-graph-green";
      else if (item === "B") return "font-graph-softgreen";
      else if (item === "C") return "font-graph-yellow";
      else if (item === "D") return "font-graph-orange";
      else if (item === "F") return "font-graph-rad";
    },
    async getReport() {
      this.isLoadReport = true;
      this.seriesPie = [];
      this.chartOptionsPie.labels = [];
      this.chartOptionsPie.colors = [];
      this.chartOptionsPie.labelsInChart = [];

      await ReportService.getReport()
        .then((res) => {
          this.provinceCount = res.data.provinceCount || 0;
          this.optCount = res.data.optCount || 0;
          this.villageCount = res.data.villageCount || 0;
          this.waterSupplySystemCount = res.data.waterSupplySystemCount || 0;
          this.managementBy_OPCCount = res.data.managementBy_OPCCount || 0;
          this.managementBy_BoardCount = res.data.managementBy_BoardCount || 0;
          this.managementBy_VillageCount =
            res.data.managementBy_VillageCount || 0;
          this.managementBy_Another = res.data.managementBy_Another || 0;
          this.itemsSummary = res.data.listTypeWater.map((m) => {
            let color = "";
            let shortness = "";
            if (m.pwaName === "แหล่งน้ำบาดาล") {
              color = "#2AD3CA";
              shortness = "บาดาล";
            } else if (m.pwaName === "แหล่งน้ำผิวดิน") {
              color = "#FDBD6E";
              shortness = "ผิวดิน";
            }
            this.chartOptionsPie.colors.push(color);
            this.chartOptionsPie.labelsInChart.push(shortness);
            const percent = m.pwaPercent.toFixed(2);

            this.seriesPie.push(m.pwaPercent);
            this.chartOptionsPie.labels.push(m.pwaName);
            return {
              ...m,
              percent: percent + " %",
              amount: this.$utils.convert.toCommas(m.pwaCount),
            };
          });
          this.isLoadReport = false;
        })
        .catch((err) => {
          this.itemsSummary = [];
          this.provinceCount = 0;
          this.optCount = 0;
          this.villageCount = 0;
          this.waterSupplySystemCount = 0;
          this.managementBy_OPCCount = 0;
          this.managementBy_VillageCount = 0;
          this.managementBy_BoardCount = 0;
          this.managementBy_Another = 0;
          this.isLoadReport = false;
        });
    },
    async getSummary() {
      this.isLoadSummary = true;
      await AssessmentService.getSummary()
        .then((res) => {
          this.total_QualitySurvey = res[0]?.total_QualitySurvey;
          this.itemsAssessmentSummary = [];
          res[0]?.dataSum.forEach((data, index) => {
            if (index < 5) {
              this.itemsAssessmentSummary.push({
                index: index + 1,
                danNane: data.danNane,
                score: data.score.toFixed(2),
                scorePercentage: data.scorePercentage.toFixed(2),
                statusText: data.statusText,
                statusTextSymbol: data.statusTextSymbol,
              });
            } else {
              this.summary.score = data.score.toFixed(2);
              this.summary.percent = data.scorePercentage.toFixed(2);
              this.summary.assessmentText = data.statusText;
              this.summary.assessment = data.statusTextSymbol;
            }
          });
          this.isLoadSummary = false;
        })
        .catch((err) => {
          this.total_QualitySurvey = 0;
          this.itemsAssessmentSummary = [];
          this.summary.score = null;
          this.summary.percent = null;
          this.summary.assessmentText = null;
          this.summary.assessment = null;
          this.isLoadSummary = false;
        });
    },
    viewSuggestion() {
      this.$utils.gotoSuggestion();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-custom .card-body {
  padding: 12px;
}
.card-custom {
  border: 0px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.card-1 {
  background-color: $white;
  height: 50px;
}
.icon-green {
  background-color: $strongcyan;
  color: $white;
}
.icon-yellow {
  background-color: $softorange;
  color: $white;
}
.header {
  background: $headerblue;
  border-radius: 4px;
  padding: 0.8rem 1rem;
  color: #484848;
}
.fontsize-icon {
  font-size: 50px;
}
.box {
  background: $white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.icon-layer {
  background-color: #ffdfd6;
  color: $orange;
  border-radius: 4px;
  padding: 10px;
}
.icon-user {
  color: #858bbe;
  background-color: #d6dfff;
  border-radius: 4px;
  padding: 10px;
}
.icon-widget {
  color: #f4bf56;
  background-color: #fff6d6;
  border-radius: 4px;
  padding: 10px;
}
.icon-other {
  color: #4bcfae;
  background-color: #b7f7e7;
  border-radius: 4px;
  padding: 10px;
}
.footer-color {
  background-color: #faf2dd;
}
</style>
